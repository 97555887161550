import { Component, OnInit } from '@angular/core';
declare var $;
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { RouteDataProvider } from '../Shared/RouteDataProvider.provider';
import { filter, pairwise } from 'rxjs/operators';
@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {

  currentRouter: string;

  constructor(private _location: Location, public activatedRoute: ActivatedRoute,
    public router: Router,
    public routerDataProvider: RouteDataProvider) {
    this.routerDataProvider.subArea.subscribe(
      m => {
        this.currentRouter = m;
        console.log(this.currentRouter)
      }
    )
  }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    $('body').addClass('hold-transition skin-blue sidebar-mini');
  }

  backClicked() {
    this._location.back();
  }

}
