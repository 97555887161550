import { Component, OnInit } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  ProgramModel,
  CampaignModel,
} from "src/app/modules/models/ProgramModel";
import { Router } from "@angular/router";
import { TeamLeaderProvider } from "../../Providers/TeamLeaderProvider";
import { UserDataProvider } from "src/app/modules/Shared/UserDataProvider";
import { RouteDataProvider } from "src/app/modules/Shared/RouteDataProvider.provider";
import { TeamLeaderDataProvider } from "../../Providers/TeamLeaderDataProvider";
import { PosmAuditTransactionsModel } from "../../Models/PosmAuditTransactionsModel";
import { WeekEnttries } from "../../Models/WeekEntries";
import { DatePipe } from "@angular/common";
import { InvoiceModel } from "src/app/modules/agents/Models/Invoice";
import { TeamLeaderService } from "../../Service/team-leader.service";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "src/app/modules/Providers/alertProvider";
import { GlobalService } from "src/app/modules/Service/SiteAuth/global.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  programList: ProgramModel[];
  disabled: boolean = false;
  campaignDisabled: boolean = false;
  posmAuditTransactions: PosmAuditTransactionsModel[];
  coolerAuditTransactions: PosmAuditTransactionsModel[];
  programId: number;
  campaignId: number;
  _errorMessage;
  posmAuditUnassignedList: PosmAuditTransactionsModel[];
  WeekEntries: WeekEnttries[] = [];
  invoiceRecords: InvoiceModel[] = [];
  campaignList: CampaignModel[];
  selectedProgram: number;
  searchRange: Date;
  minDate: Date;
  maxDate: Date;
  date1: string;
  date2: string;
  programCampaign: CampaignModel[];
  engagements: any[];
  engagements1: any[];
  _campaignId;
  _startdate;
  _enddate;
  constructor(
    public ngxLoader: NgxUiLoaderService,
    public router: Router,
    public teamLeaderProvider: TeamLeaderProvider,
    public teamLeaderDataProvider: TeamLeaderDataProvider,
    public routerDataProviders: RouteDataProvider,
    public datepipe: DatePipe,
    public teamLeaderService: TeamLeaderService,
    public alertService: AlertService,
    private toastr: ToastrService,
    private siteAuth: GlobalService
  ) {
    console.log("dashboard loading....");
  }

  ngOnInit() {
    this.getBrandingCount();
    this.getCoolerCount();
    this.getTotalCount();
    this.getWeeklyTotalCount();
  }

  brandingCount: number = 0;
  coolerCount: number = 0;
  posmEngagementCount: number = 0;
  weeklyChallengesCount: number = 0;

  getBrandingCount(): void {
    this.ngxLoader.start();
    this.teamLeaderService
      .getAPIHeaders(
        this.teamLeaderService.POSM_API + "?page=1&status=1,2,3&posm_type=2"
      )
      .subscribe(
        (response: any) => {
          this.brandingCount = response.headers.get("X-Pagination-Total-Count");
          this.ngxLoader.stop();
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  getCoolerCount(): void {
    this.ngxLoader.start();
    this.teamLeaderService
      .getAPIHeaders(
        this.teamLeaderService.POSM_API + "?page=1&status=1,2,3&posm_type=3"
      )
      .subscribe(
        (response: any) => {
          this.coolerCount = response.headers.get("X-Pagination-Total-Count");
          this.ngxLoader.stop();
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  getTotalCount(): void {
    this.ngxLoader.start();
    this.teamLeaderService
      .getAPIHeaders(
        this.teamLeaderService.POSM_API + "?page=1&posm_type=2&task=1"
      )
      .subscribe(
        (response: any) => {
          this.posmEngagementCount = response.headers.get(
            "X-Pagination-Total-Count"
          );
          this.ngxLoader.stop();
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  getWeeklyTotalCount(): void {
    this.ngxLoader.start();
    this.teamLeaderService
      .getAPIHeaders(this.teamLeaderService.PICTURE_API + "?page=1")
      .subscribe(
        (response: any) => {
          this.weeklyChallengesCount = response.headers.get(
            "X-Pagination-Total-Count"
          );
          this.ngxLoader.stop();
        },
        (error) => {
          this.alertService.error(error);
        }
      );
  }

  onChangeProgram(item) {
    this.selectedProgram = parseInt(item);
    this.disabled = true;
    if (!Number.isNaN(this.selectedProgram)) {
      this.campaignDisabled = true;
      this.programCampaign = this.campaignList.filter(
        (c) => c.program_id == this.selectedProgram
      );
    } else {
      this.campaignDisabled = false;
      this.teamLeaderProvider.getAllPosmAuditTransactionData();
    }
    this.programId = this.selectedProgram;
  }

  onChangeCampaign(item) {
    this.campaignId = item;
  }
}
